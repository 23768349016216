.calender-card-popup {
  width: 25vw;
  padding: 0 10px;
  /* padding-left: 20px; */
}

.calender-card-header {
  border-bottom: 0.5px solid var(--border-50) !important;
  padding-left: 15px;
  padding-bottom: 5px;

  /* padding-top: 13px !important; */
}

.calender-header {
  font-size: 1.146vw;
  font-weight: 600;
  padding-left: 10px;
  letter-spacing: 0.2px;
}

.calender-scheduled {
  font-size: 0.625vw;
  color: #616874;
}

.calender-popover-img {
  width: 15px;
  height: 15px;
}

.calender-popover-img:nth-of-type(1) {
  margin-top: 3px;
  /* border: 1px solid red; */
}

.first-img {
  width: 15px;
  height: 15px;
  margin-top: 2px;

  /* border: 1px solid #000; */
}

.calender-popover-title {
  padding-left: 13px;
}

.first-title {
  padding-left: 10px;
}

.calender-title {
  font-size: 0.729vw;
  font-weight: 500;
  padding-left: 10px;

  /* padding-left: 13px; */
}

.calendar-segments {
  font-size: 0.729vw;
  font-weight: 500;
  padding-left: 5px;
}

.calender-title1 {
  font-size: 0.729vw;
  font-weight: 500;
  padding-left: 10px;
}

.calender-phone1 {
  font-size: 0.729vw;
  color: #616874;
  padding-left: 10px;
}

.calender-phone {
  font-size: 0.729vw;
  color: #616874;
  padding-left: 20px;
}

.calender-edit {
  width: 22px;
  height: 22px;
  margin: 0px 5px;
}

.calender-delete {
  width: 20px !important;
  height: 20px !important;
}

.calender-border {
  width: 15px;
  height: 15px;
  border-radius: 4px;
}

.calender-popup-exit {
  width: 20px;
  height: 20px;
  margin-top: 7px;
  margin-right: 8px;
}

.calender-button {
  color: #fff;
  display: flex;
  justify-content: center;
  font-weight: 600;
  padding: 10px 0px;
}

.calender-button > button {
  background-color: #0acd95;
  font-size: 0.729vw;
  padding: 6px 15px;
  border-radius: 5px;
  letter-spacing: 0.2px;
}

.span-marketing {
  color: #ed4f9d;
  font-weight: 500;
  font-size: 0.625vw;
  background: #fdf2f8;
  padding: 3px;
  border-radius: 3px;
  margin-left: 3px;
}

.calender-data {
  padding: 10px;
  padding-left: 16px;
}
