.round {
  position: relative;
}

.round label {
  background-color: var(--background);
  border: 2px solid var(--darkgrey);
  border-radius: 50%;
  cursor: pointer;
  height: 15px !important;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px !important;
}

.round label:after {
  border: 2px solid var(--background);
  border-top: none;
  border-right: none;
  content: '';
  height: 5px;
  left: 48%;
  opacity: 0;
  position: absolute;
  top: 8%;
  transform: rotate(-45deg) translate(-45%, -20%);
  width: 7px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: var(--primary);
  border-color: var(--primary);
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}
