.broadcast-main-layout {
  height: 85vh;
  background-color: var(--BG-0);
  padding: 20px 35px;
}
.status-Language {
  color: var(--dashboardprimary);
  background: hsla(203, 78%, 95%, 1);
  border-radius: 5px;
  margin-left: 10px;
  font-size: 0.65vw !important;
  font-weight: 500;
  padding: 3px;
}
.broadcast-main-wrapper {
  background: var(--Primary-Neutral, #f4f6f8);
  padding-top: 20px;
}

.messageList-right-main-wrapper {
  width: 1570px;
}

.broadcast-wrapper {
  width: 70%;
  height: 60vh;
}

.audience {
  font-size: 0.85vw;
  font-weight: 600;
  color: var(--textBlack);
}

.audience-content {
  font-size: 0.75vw;
  font-weight: 400;
  color: var(--font-400);
  padding-top: 5px;
  padding-bottom: 15px;
}
.Audience_download > div {
  padding-bottom: 8px;
}

.Audience_download > a > div > span {
  font-size: 0.75vw;
  font-weight: 400;
  color: var(--primary);
  padding-left: 4px;
}
.Broadcast-main {
  /* background-color: var(--background); */
  margin: auto;
  /* padding: 0px 0px; */
  overflow: scroll;
  box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
}
.broadcast-main-left {
  background-color: white;
  margin-top: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.messageList-icons {
  height: 20px !important;
  display: none !important;
}

.broadcast-main-left:hover .broadcast-icons {
  display: block !important;
  padding: 0 !important;
  margin-left: 15px;
  margin-top: 2px;
}
.broadcast-icons {
  width: 20px !important;
  display: none !important;
}

.broadcast-icons1 {
  display: none;
  /* margin-bottom: 5px !important; */
}
.broadcast-main-left:hover .broadcast-icons1 {
  display: block !important;
  padding: 0 !important;
  margin-left: 15px;
  height: 0;
}
.broadcast-icons:hover {
  background: #f4f6f8 !important ;
  border-radius: 6px !important;
}

.broadcast-main-left:hover .messageList-icons {
  display: block !important;
  padding: 0 !important;
  /* margin-left: 15px; */
}

.messageList-icons1 {
  display: none;
  margin-bottom: 5px !important;
}

.messageList-icons:hover {
  background: #f4f6f8 !important ;
  border-radius: 6px !important;
}

.broadcast-main-left:hover .messageList-icons1 {
  display: block;
  margin-left: 15px;
}

.broadcast-main-container > div > span {
  font-size: 0.95vw;
  font-weight: 600;
  color: var(--textBlack);
}

.broadcast-container {
  background: var(--Primary-Neutral, #f4f6f8);
}

.broadcast-left {
  padding: 10px;
  height: 8vh;
}

.broadcast {
  background-color: white;
  height: 8vh;
  margin-top: 10px;
}

.broadcast-right-main-wrapper {
  overflow-y: hidden;
  height: 100vh;
}
.broadcast-right-wrap {
  width: 1100px;
}

.broadcast-right {
  width: 1100px;
  /* padding-left: 20px; */
}

.broadcast-right > span {
  font-size: 0.75vw;
  font-weight: 400;
  color: var(--textBlack);
}

.broadcast-right > span > img {
  width: 15px;
}
.green-background {
  color: var(--primary);
}

.broadcast-status > img {
  width: 17px;
}

.broadcast-status > span {
  font-size: 0.75vw;
  font-weight: 600;
  padding-left: 8px;
}

/* BroadcastSetting ------------------------------------- */

.BroadcastSetting-wrapper {
  width: 79vw;
  /* height: 7vh; */
  padding: 10px 20px;
  border-bottom: 1px solid var(--font-50);
  background: var(--BG-0);
}
.BroadcastSetting-wrapper > div:nth-child(4) {
  transform: translate(-10px);
}
.BroadcastSetting-wrapper > div > div > div > img {
  width: 18px;
  height: 18px;
}

.BroadcastSetting-wrapper > div > div > div > p {
  font-size: 0.75vw;
  font-weight: 600;
  padding: 10px 0px 10px 10px;
}

.button {
  margin-right: 15px;
}
.Draft-btn {
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  font-size: 0.75vw;
  font-weight: 600;
  border-radius: 6px;
  color: var(--font-600);
}

.next {
  font-size: 0.75vw;
  font-weight: 600;
  color: var(--textWhite);
  text-align: center;
}
.next > img {
  width: 17px;
}

.BroadcastSetting-mainContainer {
  width: 70%;
  height: 68vh;
  /* overflow-y: scroll; */
  background: var(--Primary-White, #fff);
}

.setting-wrapper {
  padding: 0 10px 20px 10px;
  width: 100%;
}
.disable-send-now {
  opacity: 0.35;
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.divider {
  width: 53.5vw;
  border-bottom: 1px solid var(--border-50);
}

.setting-wrapper > input {
  margin-top: 10px !important;
}

.setting-wrapper > span {
  font-size: 0.85vw;
  font-weight: 600;
  padding: 10px 0px;
  color: var(--textBlack);
}
.setting-wrapper > span:nth-child(2) {
  font-size: 0.75vw;
  font-weight: 400;
  color: var(--darkgrey);
  padding: 0;
}
.setting-setting {
  padding: 4px 0px;
}
div > .setting-setting > span {
  font-size: 0.75vw;
  font-weight: 600;
  padding: 2px 10px;
}
.setting {
  padding: 0px 10px;
}
.setting-setting > p {
  font-size: 0.75vw;
  font-weight: 400;
  color: var(--darkgrey);
  padding: 0px 0 0 10px;
}
.message-container {
  border: 1px solid var(--border-100) !important;
  border-radius: 6px;
  padding: 10px;
  /* opacity: 0.6; */
}

.setting-container > div > span {
  font-size: 0.85vw;
  font-weight: 600;
  padding: 4px 0px;
  color: var(--textBlack);
}
.setting-container > div > span:nth-child(2) {
  font-size: 0.75vw;
  font-weight: 400;
  color: var(--darkgrey);
  padding: 5px 0px;
}
.selected-name {
  font-size: 0.75vw;
  font-weight: 600;
  color: var(--textBlack);
}
.send-later-content {
  font-size: 0.75vw;
  font-weight: 600;
  color: var(--font-600);
}
.Timer-container {
  width: 20vh !important;
  padding: 0px 7px;
  border-radius: 10px;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);
}

.error-message {
  font-size: 0.65vw;
  font-weight: 500;
  color: #ff543e;
}

/* .search-template-menu {
  position: relative;
} */

.Template-data {
  position: absolute;
  top: 50px;
}
.search-popover {
  /* position: absolute; */
  /* width: 30%;
  height: auto; */
  /* bottom: -20px; */
  border-radius: 6px;
  padding: 10px 0px;
  border-radius: 10px;
  background: var(--primary-white, #fff);

  /* bottom: 20px; */
  /* top: 57%; */
  /* top: 570px; */
  /* bottom: 0% !important; */
  /* border: 1px solid #000; */
}
.popover-container {
  width: 26vw;
  padding: 0px 7px;
  border-radius: 10px;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);
}

.popover-container > div {
  padding: 0px;
  margin: 10px;
  border-radius: 6px;
}

.popover-container > div > div:hover {
  width: 23.5vw !important;
  background: var(--channelButton);
  border-radius: 6px;
}

.disabled-row {
  color: #898e99;
  font-weight: 400 !important;
  opacity: 0.4;
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.popover-container > div > div {
  width: 22vw !important;
  height: auto;
  padding: 15px 10px !important;
  /* border-bottom: 1px solid var(--font-50); */
}
.popover-container > div > div > div > div > p {
  font-size: 0.75vw;
  font-weight: 600;
}
.popover-container > div > div > span {
  font-size: 0.76vw;
  font-weight: 600;

  color: var(--channelToggleSwitch);
}

.Broadcast-Time {
  /* padding: 5px 20px; */
}
.pop {
  width: 100%;
}

.inner-content {
  border: 1px solid red;
}

.Broadcast-Time > span {
  font-size: 0.833vw;
  font-weight: 600;
  color: var(--channelToggleSwitch);
}
.broadcast-calendar {
  border-radius: 10px;
  margin: 10px;
  outline: none;
}
.broadcast-calendar .ant-popover-content {
  left: 30px !important;
}
.broadcast > span {
  font-size: 0.75vw;
  font-weight: 400;
}
.Broadcast-channel {
  display: flex;
  width: 50%;
  height: 7vh;
  border-radius: 10px;
  padding: 0px 15px 1px 15px;
  align-items: center;
  justify-content: center;
  border: 1px solid #f0f1f2;
  background: var(--Primary-White, #fff);
  box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
}
.Broadcast-channel > div > span {
  padding-left: 10px;
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--textBlack);
}

/* Broadcast-segments */

.Broadcast-segments {
  width: 100%;
  padding: 10px 10px;
  height: auto;
  max-height: 70vh;
  overflow-y: scroll;
  background: var(--Primary-White, #fff);
}
.segment-header > div {
  padding: 5px 0px;
}

.def {
  font-size: 0.85vw !important;
  font-weight: 600 !important;
  padding: 5px 0px !important;
}
.segment-header > div > span {
  font-size: 0.729vw;
  font-weight: 400;
  color: var(--font-400);
}
.segment-header .segment-left > span {
  color: var(--primary);
  font-size: 0.75vw;
  font-weight: 500;
  padding-left: 5px;
}
.segment-left > img {
  width: 16px;
  height: 16px;
}
.upload-container {
  width: 55vw;
  height: 15vh !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 5.35px;
  border: 1.5px dashed var(--font-100);
  /* margin-left: 50px; */
  background: var(--BG-25);

  input[type="file"] {
    display: none;
  }
}
.uploadLabel > div {
  display: flex;
  align-items: center;
  /* padding-top: 10px; */
}
.uploadLabel > div > p {
  font-size: 0.61vw;
  font-weight: 600;
  color: var(--font-600);
}
.duplicateButton {
  margin: 10px;
}
.duplicateButton > div {
  margin-bottom: 10px;
  font-size: 0.729vw;
  font-weight: 500;
  color: white;
}

.BroadcastSegments-container {
  padding: 0px 10px;
}
.BroadcastSegments-container > div > p {
  font-size: 0.87vw;
  font-weight: 600;
}
.BroadcastSegments-container > div > span {
  font-size: 0.729vw;
  font-weight: 500;
  color: var(--font-400);
}
.SendSetting-container {
  height: 90%;
  padding-top: 15px;
  /* overflow: scroll; */
}
/* .ant-popover {
  width: 23vw;
} */

.SendSetting-container > div > div > span {
  font-size: 0.85vw;
  font-weight: 600;
  color: var(--textBlack);
}
.SendSetting-container > div > div > img {
  width: 15px;
  height: 15px;
}
.merge-container {
  /* width: 55%; */
  max-width: fit-content;
  /* min-height: 17vw !important; */
  height: 26px;
  padding: 4px 8px;
  align-items: center;
  margin-top: 10px;
  /* gap: 6px; */
  border-radius: 5px;
  background: var(--Secondary-Amber-200, #fde68a);
}
.merge-container > img {
  width: 15px;
  height: 15px;
}
.merge-container > p {
  font-size: 0.75vw;
  font-weight: 400;
  color: var(--textBlack);
  padding-left: 5px;
}

.resp {
  padding-left: 20px;
  height: auto;
  max-height: 45vh;
  overflow-y: scroll;
}

.line {
  border-bottom: 1px solid var(--border-100);
  width: 240%;
  transform: translate(0, 29px) rotate(90deg);
  /* height: 30%; */
}

.line1 {
  border-bottom: 1px solid var(--border-100);
  width: 290%;
  transform: translate(0, 29px) rotate(90deg);
}

.Delay-container > img {
  width: 24px;
  height: 24px;
}
.Delay-container .image {
  width: 20px;
  height: 20px;
}
.Delay-container > p {
  padding-left: 10px;
  font-size: 0.75vw;
  font-weight: 500;
  /* color: var(--textBlack); */
}
.Delay-container > div > span {
  padding-left: 10px;
  font-size: 0.75vw;
  font-weight: 600;
  color: var(--textBlack);
}

.Delay-image {
  width: 24px;
  height: 24px;
  margin: 10px;
}
.segment-name {
  font-size: 0.75vw;
  font-weight: 500;
}
.wait {
  font-size: 0.82vw;
  font-weight: 500;
  color: var(--channelToggleSwitch);
}
.Delay-wrapper > div {
  /* border: 1px solid var(--BG-25); */
  /* width: 15%; */
  height: 45px;
  padding: 10px 10px;
  border-radius: 5px;
}
/* .content {
  padding-right: 30px;
} */
.upIcon {
  width: 10px;
  height: 10px;
  /* transform: rotate(180deg); */
}
.downIcon {
  width: 10px;
  height: 10px;
}

.Time-container {
  font-size: 0.82vw;
  font-weight: 500;
  color: var(--channelToggleSwitch);
}
.Delay-wrapper .segment-container {
  border: 1px solid var(--font-50);
  height: 10vh;
  width: 90% !important;
}

.increment {
  width: 100%;
  /* width: 20vw; */

  /* border: 1px solid var(--font-50); */
  /* height: 10vh; */
}
.Delay-wrapper .segment-container > .detail {
  width: 8vw;
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--channelToggleSwitch);
  padding-left: 4px;
}
.send_later {
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--textBlack);
}
.date-time {
  height: 4.5vh;
  width: 7vw;
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--textBlack);
  padding-left: 7px;
  border: 1px solid var(--font-50);
  border-radius: 6px;
  cursor: pointer;
}
.segment-container > p {
  font-size: 0.78vw;
  font-weight: 600;
  color: var(--font-600);
}
.zero-value {
  /* color: white; */
  width: 15vw;
  -moz-appearance: textfield; /* Firefox */
  color: var(--font-600);
  font-size: 0.76vw;
  font-weight: 500;
  visibility: none !important;
}
.segment-container > div > .Audience-count {
  font-size: 0.87vw;
  font-weight: 600;
  color: var(--textBlack);
}
.contacts-details {
  color: var(--font-400);
  font-size: 0.75vw;
  font-weight: 500;

  padding-left: 20px;
}

.contacts-details > span {
  color: var(--textBlack);
  font-weight: 700;
  padding-right: 5px;
}
.segment-container > div > div {
  /* width: 50%; */
  display: flex;
  flex-direction: row;
  /* height: 5vh; */
  margin-left: 10px;
  border-radius: 5px;
}
.segment-container > div > div > p {
  padding: 1px;
  color: var(--font-200);
  font-size: 0.76vw;
  font-weight: 500;
}

.Delay-wrapper .segment-containers {
  border: 1px solid var(--font-50);
  height: 10vh;
  width: 38% !important;
}

.Delay-wrapper .segment-containers > .detail {
  width: 8vw;
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--channelToggleSwitch);
  padding-left: 4px;
}
.segment-containers > p {
  font-size: 0.78vw;
  font-weight: 600;
  color: var(--font-600);
}

.segment-containers > div > .Audience-count {
  font-size: 0.87vw;
  font-weight: 600;
  color: var(--textBlack);
}

.segment-containers > div > div {
  /* width: 50%; */
  display: flex;
  flex-direction: row;
  /* height: 5vh; */
  margin-left: 10px;
  border-radius: 5px;
}

.segment-containers > div > div > p {
  padding: 1px;
  color: var(--font-200);
  font-size: 0.76vw;
  font-weight: 500;
}

.Delay-container {
  margin: 15px -5px;
}

.count {
  width: 40%;
  height: 5vh;
  border: 1px solid var(--font-50);
  margin-left: 10px;
  border-radius: 5px;
  padding: 5px 5px;
}
.count > p {
  padding: 7px;
  color: var(--textBlack);
  font-size: 0.75vw;
  font-weight: 500;
}
.Add-task {
  /* border: 1px solid var(--font-50); */
  font-size: 0.75vw;
  font-weight: 600;
  padding: 6px 10px;
  /* margin-left: 10px; */
  /* border-radius: 5px; */
}
.Delay-wrapper {
  width: 60%;
}

/* individual */
.Delay-wrapper > div {
  margin: 10px 0px 10px 0px;
}

.Delay-wrapper > span {
  color: var(--font-600);
  font-size: 0.76vw;
  font-weight: 500;
  padding-left: 10px;
}
.delay-minutes {
  font-size: 0.65vw !important;
  font-weight: 500 !important;
  color: var(--textBlack) !important;
}

.delay-image {
  width: 20px !important;
  height: 20px !important;
}
.delay-error {
  border: 1px solid red;
}
.contents {
  border-right: 1px solid var(--font-200);
  height: 2vh;
  padding-right: 10px;
}
.broad-details {
  color: var(--font-600);
  font-size: 0.76vw;
  font-weight: 500;
  padding-right: 10px;
  border-right: 1px solid var(--font-200);
}
.broad-detail {
  color: var(--font-600);
  font-size: 0.76vw;
  font-weight: 500;
  padding-right: 10px;
}

.error-message {
  font-size: 0.85vw;
  color: #ff543e;
  /* padding-left: 80px; */
}

/* BroadcastMessage */
.BroadcastMessage-container {
  background-color: var(--BG-0);
  margin: 10px 10px;
  height: 65vh;
  overflow: hidden;
}
.broadcast-wrapper {
  /* width: 50%; */
  overflow: hidden;
}

.broadcast-wrapper > div > p {
  font-size: 0.96vw;
  font-weight: 600;
  padding: 5px 0px;
}
.broadcast-wrapper > div > span {
  font-size: 0.729vw;
  font-weight: 500;
  color: var(--darkgrey);
}
.select-template {
  padding: 10px 10px;
  border-radius: 10px;
  height: auto;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);
  /* box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1); */
}

.select-template > div > span {
  font-size: 0.7vw;
  font-weight: 500;
  color: var(--darkgrey);
}

.selecting-template > span {
  font-size: 0.85vw;
  font-weight: 600;
  color: var(--channelToggleSwitch);
  letter-spacing: 0.2;
}

.selecting-template > div > img {
  margin-left: 10px;
}

.search-template-menu {
  /* height: 30vh;
  overflow: scroll; */
  border-radius: 10px;
  box-shadow: 0px 4px 30px 0px hsla(220, 9%, 19%, 0.1);
}
.search-template-data {
  height: 25vh;
  overflow: scroll;
}

.search-container {
  padding: 5px;
  border-radius: 7px;
}

.search-template-info {
  height: 4vh;
  margin: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--font-100);
}

.search-template-info > p {
  font-size: 0.78vw;
  font-weight: 500;
  color: var(--channelToggleSwitch);
}

.template-name-info {
  padding-left: 5px;
  font-size: 0.88vw;
  font-weight: 600;
  /* color: var(--channelToggleSwitch); */
}

.search-template-info .status-color {
  font-size: 0.68vw;
  font-weight: 500;
  padding: 3px;
  margin-left: 10px;
  border-radius: 5px;
}
.select-name > p {
  font-size: 0.75vw;
  font-weight: 400;
  color: var(--darkgrey);
  padding: 10px 0px 0 0px;
}
.Template-container {
  padding: 10px 10px;
  /* width: 70vh !important; */
}
.Template-wrapper {
  overflow: hidden;
}
.Template-wrapper > div > p {
  font-size: 0.78vw;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 235px;
}
.status-main-container {
  width: 100%;
  /* height: 23vh; */
  height: auto;
  padding: 0px 0px;
  /* overflow-y: scroll; */
}

.input-dropdown > div > .input-data {
  width: 30vh;
  height: 5vh;
  padding: 10px 10px;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);
  font-size: 0.88vw;
  color: var(--font-600);
}

.input-heading {
  width: 40vh;
  border-bottom: 1.5px solid var(--BG-50);
  padding: 7px 0px;
  font-size: 0.76vw;
}

.status-line > .status-input > div {
  width: 100%;
  padding: 10px 10px;
  border-radius: 4px;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);
}

.status-main-container > .input-heading > div > p {
  font-size: 0.82vw;
  font-weight: 500;
}
.status-main-container > .input-heading > p {
  width: 30%;
  padding: 10px;
  font-size: 0.82vw;
  font-weight: 500;
}
.status-input > div {
  display: flex;
  width: 21vh;
  height: 4vh;
  padding: 0px 0px;
  margin-top: 12px;
  border-radius: 4px;
  border: none !important;
  /* border: 1px solid var(--BG-50); */
  /* background: var(--textWhite); */
}
.status-input > div > span {
  color: var(--textBlack);
  font-size: 0.78vw;
  font-weight: 500;
}
.statusLeftSide-input {
  width: 50%;
  height: 5vh;
}
.statusLeftSide-input > .input-filed {
  width: 100%;
  height: 4vh !important;
  /* position: relative; */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);
  margin-top: 12px;
}
.input-dropdown {
  border: 0px solid var(--BG-50);

  right: 40%;
  top: 20%;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid #000;
}
.input-filed:hover {
  /* background-color: var(--BG-50); */
}
.input-filed > p {
  font-size: 0.78vw;
  font-weight: 500;
  color: var(--darkgrey);
}

.input-dropdown > div > .input-data {
  width: 35vh;
  height: 5vh;
  padding: 10px 10px;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);
  font-size: 0.88vw;
  font-weight: 500;
  color: var(--font-600);
}
.status-color {
  font-size: 0.65vw;
  font-weight: 500;
  margin-left: 10px;
  border-radius: 5px;
  padding: 3px 5px;
}

/* Template-preview------------------------------------------------ */
