:root {
  --background: white;
  --authDivider: #d1d3d8;
  --textBlack: #2d3036eb;
  --textWhite: #fff;
  --lightGrey: #d1d3d8;
  --darkgrey: #898e99;
  --primary: #0acd95;
  --primaryLight: rgba(37, 194, 119, 0.3);
  --TextPrimary: #25c277;
  --primaryBackground: #f0fdf4;
  --red: #eb4335;
  --card-border: #e0e2e7;
  --radio-tick: #66bb6a;
  --title: #081710;
  --content: #333843;
  --placeholder: #a3a9b6;
  --a: #f6a723;
  --black: black;
  --dashboardprimary: #4aacea;
  --dashboardname: #1f2125;
  --fieldWarning: #eb4335;
  --appHeaderBG: #fff;
  --layoutBorder: #f0f1f3;
  --dashboardback: #4d5464;
  --mainLayoutBG: #f9f9f9;
  --white: white;
  --channelButton: #f4f6f8;
  --channelToggleSwitch: #616874;
  --uploadImageBG: #c2c6ce;
  --badge: #f4f6f8;
  --primaryBG: #f0fdf4;
  --avatarBackground: #ffe6b3;
  --secondary: #f6a723;
  --textareaPlaceholder: #9ca1aa;
  --lightRed: #ffeeed;
  --fontRed: #f96056;
  --reviewStatus: #4c90f5;
  --approvedStatus: #8b5cf6;
  --primaryOpacity: #fffbeb;
  --backgroundRed: #f96056;
  --contentText: #2d3036;
  --informationBlue: #1778f2;
  --hashTag: #0966c2;
  --interaction: #4d4d4d;
  --whatsapptext: #616874;
  --searchBackground: #ebf4ff;
  --pintrestBg: #cb2027;
  --statusFont: #ed4f9d;
  --statusBg: #fdf2f8;
  --BoxShadow: #1018281a;
  --channelMessage: #f5ebe3;
  --borderGreen: #83eba9;
  --campaignColor: #c837ab;
  --BrandLink: #6940f2;
  --font-50: #f0f1f2;
  --font-100: #d1d3d8;
  --font-200: #bbbec5;
  --font-300: #9ca1aa;
  --font-400: #898e99;
  --font-500: #6b7280;
  --font-600: #616874;
  --font-700: #4c515b;
  --font-800: #3b3f46;

  --BG-25: #f4f6f8;
  --BG-0: #fff;
  --BG-50: #f0f1f2;
  --BG-100: #d1d3d8;
  --BG-200: #bbbec5;
  --BG-300: #9ca1aa;
  --BG-400: #898e99;
  --BG-500: #6b7280;
  --BG-600: #616874;
  --BG-700: #4c515b;
  --BG-800: #3b3f46;

  --borderWhite: #fff;
  --borderPrimary: #25c277;
  --border-0: #fff;
  --border-50: #f0f1f2;
  --border-100: #d1d3d8;
  --border-200: #bbbec5;
  --border-300: #9ca1aa;
  --border-400: #898e99;
}

[theme="dark"] {
  --background: black;
  --authDivider: #d1d3d8;
  --textBlack: #2d3036eb;
  --textWhite: #fff;
  --lightGrey: #d1d3d8;
  --darkgrey: #898e99;
  --primary: #25c277;
  --primaryLight: rgba(37, 194, 119, 0.3);
  --TextPrimary: #25c277;
  --primaryBackground: #f0fdf4;
  --red: #eb4335;
  --card-border: #e0e2e7;
  --radio-tick: #66bb6a;
  --title: #081710;
  --content: #333843;
  --placeholder: #a3a9b6;
  --a: #f6a723;
  --black: black;
  --dashboardprimary: #4aacea;
  --dashboardname: #1f2125;
  --fieldWarning: #eb4335;
  --appHeaderBG: #fff;
  --layoutBorder: #f0f1f3;
  --dashboardback: #4d5464;
  --mainLayoutBG: #f9f9f9;
  --white: white;
  --channelButton: #f4f6f8;
  --channelToggleSwitch: #616874;
  --uploadImageBG: #c2c6ce;
  --badge: #f4f6f8;
  --primaryBG: #f0fdf4;
  --avatarBackground: #ffe6b3;
  --secondary: #f6a723;
  --textareaPlaceholder: #9ca1aa;
  --lightRed: #ffeeed;
  --fontRed: #f96056;
  --reviewStatus: #4c90f5;
  --approvedStatus: #8b5cf6;
  --primaryOpacity: #fffbeb;
  --backgroundRed: #f96056;
  --informationBlue: #1778f2;
  --hashTag: #0966c2;
  --interaction: #4d4d4d;
  --searchBackground: #ebf4ff;
  --pintrestBg: #cb2027;
  --statusFont: #ed4f9d;
  --statusBg: #fdf2f8;

  --font-50: #f0f1f2;
  --font-100: #d1d3d8;
  --font-200: #bbbec5;
  --font-300: #9ca1aa;
  --font-400: #898e99;
  --font-500: #6b7280;
  --font-600: #616874;
  --font-700: #4c515b;
  --font-800: #3b3f46;

  --BG-0: #fff;
  --BG-25: #f4f6f8;
  --BG-50: #f0f1f2;
  --BG-100: #d1d3d8;
  --BG-200: #bbbec5;
  --BG-300: #9ca1aa;
  --BG-400: #898e99;
  --BG-500: #6b7280;
  --BG-600: #616874;
  --BG-700: #4c515b;
  --BG-800: #3b3f46;

  --borderWhite: #fff;
  --borderPrimary: #25c277;
  --border-0: #fff;
  --border-50: #f0f1f2;
  --border-100: #d1d3d8;
  --border-200: #bbbec5;
  --border-300: #9ca1aa;
  --border-400: #898e99;
}
