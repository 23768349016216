.overViewWrapper {
  width: 100%;
  height: auto;
  background: var(--background);
  margin-bottom: 30px;
  padding: 0 20px;
}
.overViewWrapper .daysWrapper {
  height: 45px;
  border-bottom: 0.5px solid var(--border-50);
}
.overViewWrapper .weekDays {
  border-right: 0.5px solid var(--border-50);
  padding-left: 15px;
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--textBlack);
}

.overViewWrapper .weekDays:last-child {
  border: none;
}

.plus-hover {
  visibility: none;
}

.overViewWrapper .dateRow {
  min-height: 150px;
  height: auto;
  /* max-height: 200px; */
  width: 100%;
  /* border-bottom: 0.5px solid var(--border-50); */
  border-bottom: 0.5px solid #e8e8ea;
}
.overViewWrapper .individualDate {
  border-right: 0.5px solid #e8e8ea;
  width: 100%;
  height: auto;
  overflow-y: auto;
  padding: 7px;
}
.overViewWrapper .individualDate:last-child {
  border: none;
}
.overViewWrapper .in-prev-month,
.overViewWrapper .in-next-month {
  color: var(--textBlack);
  background: var(--BG-25);
}

/* calendarCard and boardCard*/
.calendarCard {
  border-radius: 10px;
  border: 1px solid var(--border-50);
  min-height: 58px;
  background: var(--background);
  box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
  padding: 6px 4px;
}
.boardCard {
  /* width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
  width: 100% !important;
  border-radius: 10px;
  border: 1px solid var(--border-50);
  max-height: 109px;
  background: var(--background);
  box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
  padding: 6px 4px;
  overflow: hidden;
  transform: scale(1);
  z-index: 0;
  transition: transform 0.3s ease-in-out;
}

.calendarCard .verticalLine {
  width: 3px;
  height: 90%;
  border-radius: 20px;
  background: #f96056 !important;
  margin-left: 0;
}
.boardCard .verticalLine {
  width: 3px;
  height: 80%;
  border-radius: 20px;
  background: #f96056 !important;
}
.taskName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90px;
  font-size: 0.65vw;
  font-weight: 500;
  text-align: left;
}
.statusDay {
  font-size: 0.6vw;
  font-weight: 400;
  letter-spacing: 0.2px;
}
.calendarCard .actionIcon {
  width: 10px;
  height: 10px;
}
.calendarCard .campaignActions,
.boardCard .campaignActions {
  font-size: 0.65vw;
  font-weight: 600;
  color: var(--font-400);
  margin-left: 3px;
}
.calendarCard .multipleAvatar {
  margin-left: -5px;
}
.positioningCalendarDate {
  width: 90%;
  bottom: 7px;
  right: 8px;
  display: flex;
  justify-content: space-between;
}

.today-date {
  border: 1.4px solid #6940f2;
  border-radius: 46%;
  padding: 4px 12px;
  color: #6940f2;
}

.boardDate {
  font-size: 0.6vw;
  font-weight: 600;
  color: var(--font-400);
  margin: 0 3px;
}
.boardCard .content {
  font-size: 0.7vw;
  font-weight: 500;
  color: var(--textBlack);
  letter-spacing: 0.3px;
}

.createTaskCard {
  border-radius: 10px;
  min-height: 65px;
  background: var(--background);
  padding: 10px 15px;
  width: 94%;
}
.createTaskInput {
  width: 100%;
  border: none;
  outline: none;
  font-size: 0.8vw;
  font-weight: 400;
  border-radius: 10px;
  background-color: var(--background);
}
.createTaskInput::placeholder {
  text-align: left;
  color: var(--placeholder);
  font-size: 0.8vw;
}
.boardWrapper .statusName {
  font-size: 0.85vw;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: var(--textBlack);
}

/* calendar-post  */

.calendar-post-time {
  font-size: 0.62vw;
  font-weight: 500;
  color: var(--font-400);
}

.calendar-post {
  font-size: 0.62vw;
  font-weight: 500;
  color: var(--primary);
}

.card-post-menu {
  display: none !important;
}

.calendarCard:hover .card-post-menu {
  display: block !important;
}

.boardCardWhatsapp {
  width: 15px;
  height: 15px;
}

.boardCardText {
  padding-left: 5px;
  font-size: 0.62vw;
  text-align: start;
  font-weight: 500;
  color: #2d3036;
  width: 8vw;
  /* width: calc(100% - 30%); */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.boardCardRunning {
  width: 14px;
}

.boardTime {
  font-size: 0.521vw;
  font-weight: 500;
  color: #898e99;
  padding-left: 3px;
  overflow: hidden !important;
}

.boardCardSegment {
  width: 14px;
  height: 14px;
}

.boardSegment {
  font-size: 0.521vw;
  padding-left: 2px;
  font-weight: 500;
  /* width: 2vw; */
}

.boardSegment > span {
  color: #2d3036;
}

.show-button {
  font-size: 0.62vw;
  font-weight: 400;
  color: #6940f2;
  margin-top: 18px;
  margin-left: 7px;
}

.boardCardArrow {
  width: 7px;
  height: 7px;
}

.boardCardRow {
  margin: 3.5px 0px;
  width: 2.5px;
  height: auto;
  border-radius: 15px !important;
  margin-right: 4px;
}

.flex-card {
  /* border: 1px solid black; */
  width: 100% !important;
  overflow: hidden;
  white-space: nowrap;
}

.card-overlap:not(.expanded):nth-child(2),
.card-overlap:not(.expanded):nth-child(3),
.card-overlap:not(.expanded):nth-child(4),
.card-overlap:not(.expanded):nth-child(5) {
  margin-left: -30px;
  white-space: nowrap;
}

.asc:not(.expanded):nth-child(2),
.asc:not(.expanded):nth-child(3),
.asc:not(.expanded):nth-child(4),
.asc:not(.expanded):nth-child(5) {
  margin-top: -25px;
  white-space: nowrap;
}

.boardCardText-flex {
  width: 8vw;
  /* width: calc(100% - 70%); */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.expanded {
  transform: scale(1.1);
  z-index: 1;
  /* width: 100% !important;
  box-shadow: 0px 4px 30px 0px #e9e9e9; */
  /* transition: width 9s ease; */
}

.hidden {
  display: none !important;
  /* pointer-events: none; */
  /* z-index: -1; */
  /* transition: transform 9s ease; */
}

.calenderIcons {
  font-size: 0.55vw;
  font-weight: 500;
  padding: 0px 5px;
}

/* .boardTime-flex {
  width: calc(100% - 50%);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
} */
