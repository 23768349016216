.loader {
  border-radius: 50% !important;
  /* border: 6px solid #f3f3f3;
  border-top: 6px solid green;
  width: 30px;
  height: 30px; */
  -webkit-animation: spin 1s linear infinite !important; /* Safari */
  animation: spin 1s linear infinite !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* global loader */

@keyframes ldio-ngzhrv9xffn {
  0% {
    transform: translate(13.440000000000001px, 89.60000000000001px) scale(0);
  }
  25% {
    transform: translate(13.440000000000001px, 89.60000000000001px) scale(0);
  }
  50% {
    transform: translate(13.440000000000001px, 89.60000000000001px) scale(1);
  }
  75% {
    transform: translate(89.60000000000001px, 89.60000000000001px) scale(1);
  }
  100% {
    transform: translate(165.76000000000002px, 89.60000000000001px) scale(1);
  }
}
@keyframes ldio-ngzhrv9xffn-r {
  0% {
    transform: translate(165.76000000000002px, 89.60000000000001px) scale(1);
  }
  100% {
    transform: translate(165.76000000000002px, 89.60000000000001px) scale(0);
  }
}
@keyframes ldio-ngzhrv9xffn-c {
  0% {
    background: #f96056;
  }
  25% {
    background: #25c277;
  }
  50% {
    background: #f6a723;
  }
  75% {
    background: #6366f1;
  }
  100% {
    background: #f96056;
  }
}
.ldio-ngzhrv9xffn div {
  position: absolute;
  /* width: 44.800000000000004px;
  height: 44.800000000000004px; */
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(89.60000000000001px, 89.60000000000001px) scale(1);
  background: #f96056;
  animation: ldio-ngzhrv9xffn 2.4390243902439024s infinite
    cubic-bezier(0, 0.5, 0.5, 1);
}
.ldio-ngzhrv9xffn div:nth-child(1) {
  background: #6366f1;
  transform: translate(165.76000000000002px, 89.60000000000001px) scale(1);
  animation:
    ldio-ngzhrv9xffn-r 0.6097560975609756s infinite cubic-bezier(0, 0.5, 0.5, 1),
    ldio-ngzhrv9xffn-c 2.4390243902439024s infinite step-start;
}
.ldio-ngzhrv9xffn div:nth-child(2) {
  animation-delay: -0.6097560975609756s;
  background: #f96056;
}
.ldio-ngzhrv9xffn div:nth-child(3) {
  animation-delay: -1.2195121951219512s;
  background: #6366f1;
}
.ldio-ngzhrv9xffn div:nth-child(4) {
  animation-delay: -1.8292682926829267s;
  background: #f6a723;
}
.ldio-ngzhrv9xffn div:nth-child(5) {
  animation-delay: -2.4390243902439024s;
  background: #25c277;
}
.loadingio-spinner-ellipsis-twdnr17yzcs {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-ngzhrv9xffn {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ngzhrv9xffn div {
  box-sizing: content-box;
}
.globalLoaderWrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1009 !important;
  background: rgba(45, 48, 54, 0.25);
}
