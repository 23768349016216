.createCampaignLabel {
  padding: 0px 10px;
}
.create-input {
  width: 97.8%;
  border: 1px solid var(--border-50);
  border-radius: 6px;
  margin: 10px;
}

.create-inputBox {
  width: 100%;
  height: 46px;
  border-radius: 6px;
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--textBlack);
}
.createCampaignLabel > span {
  font-size: 0.835vw;
  font-weight: 600;
}
.createCampaignLabel1 > span {
  padding: 0px 10px;
  font-size: 0.835vw;
  font-weight: 600;
}
.select-campaign {
  padding: 0px 10px;
  font-size: 0.835vw;
  font-weight: 600;
}
.calender-card > div > div > div > p {
  font-size: 0.85vw;
  font-weight: 600;
  /* padding: 10px 10px; */
}
.popup-footer {
  margin: 10px 0px;
}

.footerImgBig {
  width: 25px;
  height: 25px;
  z-index: 200 !important;
  margin-left: 5px;
}
.color-container > span {
  font-size: 0.85vw;
  font-weight: 600;
}
.select-calendar {
  width: 19vw;
  height: 32px;
  padding: 5px 5px;
  border-radius: 5px;
  border: 1px solid var(--lightGrey);
  background: var(--textWhite);
}
.color-name {
  color: var(--channelToggleSwitch);
}

.disable-button {
  padding: 6px 10px;
  background-color: var(--lightGrey);
  border-radius: 6px;
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--textBlack);
}
