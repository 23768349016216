.leftListLayout {
  width: 15vw;
  border: 1px solid var(--layoutBorder);
  height: 100%;
  background-color: var(--background);
  padding: 10px 15px;
}
.listText {
  font-size: 0.83vw;
  font-weight: 500;
  padding: 10px 15px;
  margin: 7px 0px;
  letter-spacing: 0.2px;
  color: var(--textBlack);
  cursor: pointer;
}

.listText:hover {
  background: var(--channelButton);
  border-radius: 6px;
}
.listText.active-listText {
  width: 100%;
  background-color: #0acd95;
  color: var(--white) !important;
  font-weight: 600 !important;
  border-radius: 8px;
  padding: 10px 15px;
}

.listText:not(.active-listText):hover {
  background-color: var(--channelButton);
  color: var(--textBlack) !important;
  font-weight: 600 !important;
  border-radius: 8px;
  padding: 10px 15px;
}

.listText > div > img {
  width: 15px;
}

.listText1 {
  font-size: 0.8vw;
  font-weight: 500;
  color: var(--font-600);
  margin-top: 8px;
  padding: 10px;
}

.submenu-container {
  margin-left: 20px;
}

.listText1.active-listText {
  width: 100%;
  background-color: #0acd95;
  color: var(--white) !important;
  font-weight: 600 !important;
  border-radius: 8px;
  padding: 10px 15px;
}

.listText1:not(.active-listText):hover {
  background-color: var(--channelButton);
  color: var(--textBlack) !important;
  font-weight: 600 !important;
  border-radius: 8px;
  padding: 10px 15px;
}

.active-listText {
  width: 100%;
  background-color: #0acd95;
  color: var(--white) !important;
  font-weight: 600 !important;
  border-radius: 8px;
}

.rotate-img1 {
  rotate: 180deg;
  transition: all 0.2s ease-in-out;
}
