.contacts-main-layout {
  height: 85vh;
  background-color: var(--BG-0);
  padding: 20px 35px;
  overflow-y: scroll;
}
.api-search_container {
  width: 17vw;
  padding: 10px 0px;
}
