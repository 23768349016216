.messageList-main-container {
  height: 100vh;
  padding: 10px 20px 20px 20px;
}

.messageList-main-container > span {
  font-size: 0.95vw;
  font-weight: 600;
  color: var(--textBlack);
}
.messageList-container1 {
  /* height: 55vh; */
  background: var(--channelButton);
  padding: 10px 20px;
}
.messageList-main-header {
  width: 100%;
  min-width: 1600px;
}

.messageList-left-header {
  width: 35%;
}

.messageList-names {
  font-size: 0.85vw;
  font-weight: 500;
  color: var(--textBlack);
}

.messageList-names-center {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.messageList-main-wrapper {
  width: 100%;
  min-width: 1580px;
  margin: 10px 0px;
  padding: 10px 10px;
  border-radius: 10px;
  background: var(--background);
}

.messageList-main-wrapper:hover .messageList-icons {
  display: block;
}

.messageList-icons {
  /* width: 30px; */
  height: 23px;
  padding-left: 15px;
  display: none;
}

.messageList-left > div > span {
  font-size: 0.85vw;
  font-weight: 500;
  color: var(--textBlack);
}

.messageList-left-column > span:nth-child(1) {
  font-size: 0.65vw;
  font-weight: 400;
  color: var(--font-600);
}

.messageList-left-column > img {
  width: 18px;
  padding-left: 5px;
}

.messageList-left-column > span:nth-of-type(2) {
  font-size: 0.65vw;
  font-weight: 500;
  color: var(--font-600);
  padding-left: 5px;
}

.messageList-right > span {
  font-size: 0.75vw;
  font-weight: 400;
  color: var(--textBlack);
}

.messageList-left-column {
  padding-top: 12px;
}

/* plate -------------------------- */

.no-template {
  transform: translateX(70px);
}

.no-template-main-container {
  height: 100%;
  min-height: 60vh;
}

.no-template-main-container > img {
  width: 15% !important;
  height: 100%;
  /* margin-top: 30px; */
}

.no-template-title {
  font-size: 1.25vw !important;
  font-weight: 600 !important ;
  color: var(--textBlack) !important;
  padding-top: 10px;
}
.no-template-content {
  width: 22%;
  font-size: 0.78vw !important;
  font-weight: 600 !important;
  color: var(--font-600) !important ;
  text-align: center !important;
  padding-top: 10px !important;
}

.preview {
  background-color: white !important;
  width: auto;
  height: 59vh;
  border-radius: 15px;
  /* padding: 20px; */
  padding: 20px;
}

.no-broadcast-main-container {
  height: 100%;
  min-height: 55vh;
}
