.MessagePreview-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* position: absolute; */
}
.messagePreview-wrap {
  width: "95%";
  height: "100%";
  min-height: 50vh;
  padding: "10px";
  box-shadow: " 0px 1.5px 2px 2px rgba(16, 24, 40, 0.1)";
  border-radius: "25px 25px 25px 25px";
}

.template-image-main-container {
  width: 100%;
}
.template-image-main-container {
  width: 100%;
}
.wwww {
  width: 100%;
  background-color: var(--channelButton);
  border-radius: 0px 0px 25px 25px;
}
.wwww > div > img {
  width: 16px;
  height: 16px;
}
.preview-footer-box {
  width: 11vw;
  background-color: var(--textWhite);
  border: #bbbec5;
  border-radius: 10px;
  padding: 5px;
}
.preview-footer-box > img {
  width: 16px;
  height: 16px;
}
.whatsapp-header {
  width: 100%;
  background-color: hsla(174, 90%, 26%, 1);
  padding: 5px 10px;
  border-radius: 25px 25px 0px 0px;
}
.template-image-main-container > img {
  background-repeat: no-repeat;
  object-fit: cover;
  background-color: #f5ebe3;
  width: 100%;
  height: auto;
  max-height: 43vh;
}

.whatsapp-header > div > div > img {
  width: 15px;
  height: 15px;
}
.whatsapp-header .whatsapp-name {
  color: var(--background);
  font-size: 0.79vw;
  font-weight: 700;
}
.whatsapp-header .whatsapp-phoneNumber {
  font-size: 0.72vw;
  font-weight: 500;
  color: var(--background);
  /* padding-right: 10px; */
}

.logo {
  border-radius: 20px;
  width: 35px;
  height: 35px;
}

.broadcast-image-container {
  width: 88%;
  position: absolute;
  top: 17%;
  /* bottom: 10px; */
  margin: 0px 10px;
  box-shadow: 0px 4px 30px 0px rgba(45, 48, 54, 0.1);
  background-color: white;
  border-radius: 10px;
  max-height: 39vh;
  height: auto;
  overflow: scroll;
}
.template-image {
  padding: 10px 10px;
}

.image-preview-g {
  width: 100% !important;
  height: 17vh;
  border-radius: 10px;
  object-fit: cover;
}

.video-preview {
  width: 100% !important;
  height: 30vh;
  border-radius: 10px;
}

.document-preview {
  width: 100% !important;
  height: 25vh;
  border-radius: 10px;
}
.template-image > img {
  width: 15vw;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
  overflow: hidden;
}

.text-container {
  padding: 0px 10px;
  /* padding: 0 0 20px 0; */
}
.header-text {
  font-size: 0.7vw !important;
  font-weight: 600 !important;
  letter-spacing: 0.2px !important;
}
.body-text {
  font-size: 0.7vw !important;
  font-weight: 400 !important;
  letter-spacing: 0.2px !important;
}
.text-container > p {
  font-size: 0.7vw;
  font-weight: 500;
  margin-top: 10px;
  color: var(--font-500);
}
.template-image > img {
  width: 10%;
}
.List {
  list-style: initial;
  font-size: 0.75vw !important;
  font-weight: 600 !important;
  color: var(--textBlack);
  padding-left: 10px;
}

.text-container-combo {
  font-size: 0.73vw !important;
  font-weight: 400 !important;
  color: var(--textBlack);
}

.final-container {
  display: flex;
  width: 100%;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 3px 0px;
  border-top: 1px solid var(--BG-50);
}
.final-container > div > span {
  color: var(--informationBlue);
  font-size: 0.75vw;
  font-weight: 500;
  font-style: normal;
  padding-left: 10px;
}
.see_all_option {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  padding: 5px 0px;
}

.see_all_option > span {
  color: var(--informationBlue);
  font-size: 0.75vw;
  font-weight: 500;
  font-style: normal;
  padding-left: 10px;
}
.see_all {
  width: 100%;
  /* border-top: 1px solid var(--BG-50); */
  padding: 5px 0px;
  display: flex;
  justify-content: center;
}
.see_all > span {
  color: var(--informationBlue);
  font-size: 0.75vw;
  font-weight: 500;
  font-style: normal;
  padding-left: 10px;
}
.drawerQuickReplyButtons > div > img {
  width: 12px;
  height: 12px;
}

.drawerContainer {
  height: 30vh;
  overflow: scroll;
}
/* REVIEW AND ACTIVATE */

.Review-container {
  width: 100%;
  height: 100%;
  padding: 20px 10px 45px 10px;
  overflow-y: scroll;
}
.Review-container .aaaaa {
  width: 58%;
}
.Review-wrapper {
  padding: 10px 0px;

  border-radius: 16px;
  border: 1px solid #f0f1f2;
  background: var(--Primary-White, #fff);
  box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
}
.Review-first-container {
  padding: 5px 10px;
}
.Review-first-container > p {
  padding: 0px 10px;
  font-size: 0.85vw;
  font-weight: 600;
  color: var(--contentText);
}
.Review-List {
}

.Review-List > div > p {
  padding: 16px 20px;

  font-size: 0.75vw;
  font-weight: 500;
  color: var(--whatsapptext);
}

.Review-left > span {
  padding: 18px 10px;
  font-size: 0.8vw;
  font-weight: 600;
  color: var(--contentText);
}

.broadcast-preview-segments {
  padding: 16px 10px !important;
  font-size: 0.8vw !important;
  font-weight: 600 !important;
  color: var(--contentText) !important;
}

.Whatsapp_name {
  font-size: 0.8vw;
  font-weight: 600;
}
.preview-segments {
  font-size: 0.75vw;
  font-weight: 400;
  color: var(--font-400);
  padding-left: 5px;
}

.Review {
  padding: 10px 0px;

  border-radius: 16px;
  border: 1px solid #f0f1f2;
  background: var(--Primary-White, #fff);
  box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
}
.Review-content {
  background-color: var(--BG-50);
  border-radius: 10px;
}
.Review-content {
  font-size: 0.75vw;
  font-weight: 600;
  color: var(--contentText);
}
input {
  border: none;
  outline: none;
}
input::placeholder {
  color: var(--primary);
  font-size: 0.75vw;
  font-weight: 500;
}
.MessagePreview {
  width: 37%;
}

.TestMessage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--Greyscale-50, #f0f1f2);
  background: var(--Primary-White, #fff);
  margin-top: 5px;
  margin-bottom: 20px;
}

.TestMessage > p {
  font-size: 0.95vw;
  font-weight: 600;
  color: var(--textBlack);
  margin-bottom: 10px;
}

.TestMessage > button {
  font-size: 0.85vw;
  background-color: #f6a723;
  font-weight: 600;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
.TestMessage > button > img {
  width: 17px;
  margin-right: 5px;
}

.broadcast-modal {
  width: 29% !important;
}

.main-numbers-test {
  padding: 10px 10px;
  height: 30vh;
}
.main-numbers-test > div {
  width: 38%;
}

.numbers-test {
  border: 1px solid var(--border-50);
  border-radius: 6px;
  margin: 0px 10px;
  padding: 0px 10px;
}

.number-input {
  border: 1px solid var(--border-100);
  border-radius: 6px;
}

.object-value {
  background-color: yellow; /* Example styling for when inputValue is an object */
  color: red;
}

.number-input-inner {
  padding: "3px 10px";
  width: "17vw";
  height: "3.5vh";
  border-radius: "6px";
  font-size: 0.75vw;
  font-weight: 600;
  color: var(--textBlack);
}

.numbers-test > p {
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--textBlack);
  padding-left: 10px;
}

.footer-test-message {
  border-top: 1px solid var(--border-50);
  padding: 10px 20px;
}

.footer-test-message > span {
  font-size: 0.75vw;
  font-weight: 400;
  color: var(--font-600);
}

.footer-cancel {
  font-size: 0.75vw;
  font-weight: 500;
  color: var(--font-600);
  padding-right: 10px;
}

.slides {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background: linear-gradient(to right, #25c277 100%, transparent 0%);
  animation: Animationslide 2s ease-in-out alternate;
}

.sliders {
  width: 40%;
  height: 7px;
  border-radius: 40px;
  background: #d1d3d8;
  overflow: hidden;
}

.slides1 {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background: linear-gradient(to right, #25c277 100%, transparent 0%);
  animation: Animationslide 2s ease-in-out alternate;
}

.sliders1 {
  width: 40%;
  height: 7px;
  border-radius: 40px;
  background-color: red !important;
  overflow: hidden;
}

.send-button {
  background-color: #25c277;
  border-radius: 5px;
  padding: 4px 10px;
}

@keyframes Animationslide {
  0% {
    transform: translateX(-100%);
    background: linear-gradient(to right, #25c277 100%, transparent 0%);
  }
  /* 50% {
    transform: translateX(0%);
    background: linear-gradient(to right, #7eac52 100%, transparent 0%);
  } */

  /* 75% {
    transform: translateX(-100%);
    background: linear-gradient(to right, #7eac52 100%, transparent 0%);
  } */
}
.loadingCount {
  color: var(--contentText);
  font-size: 0.75vw;
  font-weight: 500;
}

.bg-loader {
  background: green !important;
  padding: 20px !important;
}

progress::-moz-progress-bar {
  background-color: var(--primary) !important;
}
progress::-webkit-progress-value {
  background-color: var(--primary) !important;
  vertical-align: none;
}

.TickIcon {
  width: 16px;
  height: 16px;
}

.drawerCallToActionsButtons > div > div > img {
  width: 16px;
  height: 16px;
}

.next-button-enabled {
  color: green;
}

.next-button-disabled {
  color: red;
}

.popover_timer-container {
  border-radius: 10px;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);
}

.popover_timer-container > div {
  width: 20vh;
  height: auto;
  display: flex;
  flex-direction: column;

  padding: 10px 5px;
  border-bottom: 1px solid var(--primary);
}
/* .popover_timer-container > div > p {
} */
:where(.css-dev-only-do-not-override-zl9ks2).ant-picker-outlined {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  width: 10vw;
}

:where(.css-dev-only-do-not-override-zl9ks2).ant-picker .ant-picker-suffix {
  display: none;
}

.pop .ant-popover-inner-content {
  color: rgba(0, 0, 0, 0.88) !important;
  padding: 10px !important;
  width: 31vw !important;
}

.antd .ant-popover-content {
  width: 15vw !important;
  position: relative !important;
}

/* -------------------- */

.no-message {
  width: 100%;
  height: 60vh;
  background-color: var(--channelButton);
}

.ttt {
  background: none;
}
.no-message > div > img {
  width: 12vw;
  /* height: 20px; */
}

.no-message > div > span {
  font-size: 0.85vw;
  font-weight: 500;
  color: var(--font-400);
}
.broadcast-wrapper .select-heading > span {
  font-size: 0.95vw;
  font-weight: 600;
  color: #2d3036;
}
.select-heading > span:nth-child(2) {
  font-size: 0.75vw;
  font-weight: 500;
  color: #898e99;
}
.At {
  color: var(--channelToggleSwitch);
}

.time-popover .ant-popover-content {
  right: 50px !important;
  top: 0px;
}
