@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Inter', sans-serif !important;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  background-color: var(--background);
}

::-webkit-scrollbar {
  display: none; /* Might be enough */
  background: transparent !important;
  visibility: hidden;
  width: 0px;
}

.listScroll:hover::-webkit-scrollbar-thumb {
  background: var(--BG-100) !important;
  border-radius: 50px !important;
}

.listScroll::-webkit-scrollbar {
  display: block !important;
  width: 4px !important;
  background: transparent !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* antd */
.ant-form-item {
  margin: 0 !important;
}
.ant-form-item-explain .ant-form-item-explain-error {
  color: var(--fieldWarning) !important;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  margin-top: 5px !important;
  display: flex;
  gap: 5px;
}
.ant-form-item-explain .ant-form-item-explain-error::before {
  content: '';
  background: url('/src/assets/icons/alert-triangle.png');
  height: 15px;
  width: 15px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  background-position: center;
}
.ant-modal-content {
  padding: 0 !important;
}
.ant-color-picker-panel {
  padding: 5px;
}
.ant-color-picker-color-block {
  display: none !important;
}
.ant-color-picker-input-container {
  display: none !important;
}

.ant-picker-footer {
  display: none !important;
}

.ant-picker-header-super-next-btn {
  display: none !important;
}
.ant-picker-header-super-prev-btn {
  display: none !important;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
  cursor: not-allowed !important;
  background-color: none !important;
  color: inherit !important;
  background-color: transparent !important;
}
.ant-picker-cell-disabled .ant-picker-cell-in-view {
  background-color: transparent !important;
}

.ant-picker-cell-disabled {
  pointer-events: visible !important;
}

/* antd popover  */
.ant-popover .ant-popover-inner {
  border-radius: 10px !important;
  box-shadow: 0px 4px 30px 0px rgba(45, 48, 54, 0.1) !important;
  padding: 0px !important;
}

/* .ant-popover .ant-popover-content {
  right: 20px;
  top: 0px;
} */

.ant-color-picker-input-container {
  display: none;
}

.ant-picker-dropdown {
  box-shadow: 0px 4px 30px 0px rgba(45, 48, 54, 0.1) !important;
  border: none !important;
}
.ant-picker-panel-layout {
  box-shadow: 0px 4px 30px 0px rgba(45, 48, 54, 0.1) !important;
  border-radius: 10px !important;
  border: green !important;
}

.ant-picker-cell-inner {
  color: var(--textBlack) !important;
}

.ant-picker-cell-in-view {
  color: var(--textBlack) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today {
  /* background-color: var(--primaryBackground) !important;
  border: 1px solid var(--primaryBackground) !important; */
  border-radius: 6px;
  padding: 0px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none !important;
}

.ant-picker-cell-selected.ant-picker-cell-inner {
  background: var(--primaryBackground) !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background: var(--primaryBackground) !important;
}

.ant-form-item-explain {
  display: none;
}

/* multipleRowIcons */
.multipleIcons:nth-child(1) {
  margin-left: 0px !important;
}
.multipleIcons {
  margin-left: -15px;
}

img {
  max-width: none !important;
}

/* .border {
  border: none;
} */

/* common */
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.space-evenly {
  justify-content: space-evenly;
}
.flex-start {
  justify-content: flex-start;
}
.flex-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.align-left {
  align-items: flex-start;
}
.align-right {
  align-items: flex-end;
}
.w-100 {
  width: 100% !important;
}
.w-95 {
  width: 95% !important;
}
.w-90 {
  width: 90% !important;
}
.w-85 {
  width: 85% !important;
}
.w-80 {
  width: 80% !important;
}
.w-75 {
  width: 75% !important;
}

.w-70 {
  width: 70% !important;
}
.w-60 {
  width: 60% !important;
}
.w-55 {
  width: 55% !important;
}
.w-58 {
  width: 58% !important;
}
.w-50 {
  width: 50% !important;
}
.w-45 {
  width: 45% !important;
}
.w-40 {
  width: 40% !important;
}
.w-30 {
  width: 30% !important;
}
.w-25 {
  width: 25% !important;
}
.w-20 {
  width: 20% !important;
}
.w-10 {
  width: 10% !important;
}

.h-100 {
  height: 100% !important;
}
.h-90 {
  height: 90% !important;
}
.h-80 {
  height: 80% !important;
}
.h-70 {
  height: 70% !important;
}
.h-60 {
  height: 60% !important;
}
.h-50 {
  height: 50% !important;
}
.h-40 {
  height: 40% !important;
}
.h-30 {
  height: 30% !important;
}
.h-20 {
  height: 20% !important;
}
.h-10 {
  height: 10% !important;
}

/* margin */
.m-5 {
  margin: 5px;
}
.mt-5 {
  margin-top: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}
.m-10 {
  margin: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
/* padding */

.pt-3 {
  padding-top: 3px;
}

.p-5 {
  padding: 5px;
}
.pt-5 {
  padding-top: 5px;
}
.pl-5 {
  padding-left: 5px;
}
.pr-5 {
  padding-right: 5px;
}
.pb-5 {
  padding-bottom: 5px;
}
.p-10 {
  padding: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}

.p-20 {
  padding: 20px;
}
.pt-20 {
  padding-top: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}

.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.pointer {
  cursor: pointer;
  /* font-size: 0.75vw;
  font-weight: 500;
  color: var(--font-600); */
  /* padding-left: 5px; */
}
.noDrop {
  cursor: no-drop;
}
.b-radius {
  border-radius: 10px;
}
.b-radius-6 {
  border-radius: 6px;
}

.border-b {
  border-bottom: 0.08vw solid var(--border-50);
}

.appWrapper {
  width: 100%;
  height: 100%;
}

.app-rightLayout {
  width: 87.3%;
  height: 100%;
  overflow-y: scroll;
}
.app-rightLayout::-webkit-scrollbar {
  display: none;
}

.weight-small {
  font-weight: 400;
}
.weight-medium {
  font-weight: 500;
}

.weight-semibold {
  font-weight: 600;
}
.weight-bold {
  font-weight: 700;
}

.weight-extrabold {
  font-weight: 800;
}

.font-xs {
  font-size: 0.65vw;
}

.font-sm {
  font-size: 0.65vw;
}
.font-md {
  font-size: 0.75vw;
}

.font-medium {
  font-size: 0.8vw;
}

.font-normal {
  font-size: 0.85vw !important;
}

.font-default {
  font-size: 0.84656vw;
}

.font-large {
  font-size: 0.9vw;
}

.font-larger {
  font-size: 0.95vw;
}

.font-largest {
  font-size: 1.2vw;
}

.mt-15 {
  margin-top: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mr-15 {
  margin-right: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-20 {
  margin-left: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}

.mr-20px {
  margin-right: 20px;
}

.br-10 {
  border-radius: 10px;
}

.br-8 {
  border-radius: 8px;
}

.br-5 {
  border-radius: 5px;
}

.main-headings {
  font-size: 0.95vw;
  font-weight: 600;
  color: var(--textBlack);
  padding: 20px;
}

.main-headings-segment {
  font-size: 0.95vw;
  font-weight: 600;
  color: var(--textBlack);
  padding: 20px 0px;
}

.main-headings-broadcast {
  font-size: 0.95vw;
  font-weight: 600;
  color: var(--textBlack);
  /* padding: 20px; */
}

.main-headings-overview {
  font-size: 0.95vw;
  font-weight: 600;
  color: var(--textBlack);
  margin-top: 20px;
}

.table-names {
  font-size: 0.85vw;
  font-weight: 400;
  color: var(--textBlack);
  text-transform: capitalize;
}

.ant-select-selection-item-content {
  padding: 0 10px;
}

.ant-select-selection-item-remove {
  padding-right: 5px;
}

.ant-input {
  border-radius: 6px;
}

.ml-40 {
  margin-left: 40px;
}

.mt-7 {
  margin-top: 7px;
}

.ant-skeleton-paragraph > li + li {
  height: 7vh !important;
}

.ant-skeleton-paragraph > li:nth-child(1) {
  display: none !important;
}

.ant-skeleton-content
  .ant-skeleton-paragraph
  > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 100% !important;
}

.ant-skeleton-title {
  display: none !important;
}

.ant-skeleton-title + .ant-skeleton-paragraph {
  margin-block-start: 0px !important;
}

.ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph > li + li {
}

.ant-modal-mask {
  inset: unset !important;
}
.ant-modal-content {
  box-shadow: none !important;
  border-radius: 15px !important;
}

/* modal-overlay */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.blurred-background {
  backdrop-filter: blur(5px); /* Add blur effect */
  background-color: rgba(0, 0, 0, 0.4); /* Add semi-transparent background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; /* Ensure it's above other content */
}

/* phone-number input arrow */

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  width: 3vw;
  -moz-appearance: textfield; /* Firefox */
  color: var(--font-600);
  font-size: 0.76vw;
  font-weight: 500;
}

.no-spinner1::-webkit-inner-spin-button,
.no-spinner1::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph > li + li {
  /* width: 20% !important; */
  /* height: 100% !important; */
}

/* .ant-skeleton-title + .ant-skeleton-paragraph {
  width: 30%;
  height: 20% !important;
} */

.loader1 {
  width: 100% !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-credentials-auto-fill-button: none !important;
}

.languageSelect.ant-select-single.ant-select-lg
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 2.5 !important;
}

.template-preview-drawer .ant-drawer-content-wrapper {
  width: 100% !important;
  height: auto !important;
}
