/* BroadcastSegment page */

.segmentHeader-container {
  width: 100%;
  height: 100vh;
  background: var(--Primary-White, #fff);
}

.segmentHeader-container > div > span {
  /* width: 100%; */
  font-size: 0.73vw;
  font-weight: 500;
}
.mmm {
  height: 6vh;
  background: var(--Primary-White, #fff);
  box-shadow: 0px 2px 8px 1px rgba(16, 24, 40, 0.05);
}
.mmm > div > span {
  font-size: 0.78vw;
  font-weight: 500;
}

.ss {
  width: 12%;
  height: 6.5vh;
  padding-top: 20px;
}

.messageList-main-container > span {
  font-size: 0.95vw;
  font-weight: 600;
  color: var(--textBlack);
}

.messageList-container {
  height: 55vh;
  background: var(--channelButton);
  padding: 10px 0 10px 20px;
  border-radius: 10px;
  /* overflow-x: scroll; */
  overflow: hidden;
}
.broadcast-left-wrapper {
  height: 100%;
  min-height: 100vh;
}
.broadcast-left-wrapper1 {
  height: 100%;
  max-height: 60vh;
  overflow: auto;
}
.broadcast-left > div > img {
  width: 18px;
}

.broadcast-left > div > span {
  font-size: 0.78vw;
  font-weight: 500;
  color: #2d3036eb;
}

.messageList-main-header {
  width: 100%;
  min-width: 1600px;
}

.messageList-left-header {
  width: 35%;
}

.messageList-names {
  font-size: 0.85vw;
  font-weight: 500;
  color: var(--textBlack);
  /* height: 26px; */
}

.messageList-names-center {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.messageList-main-wrapper {
  width: 100%;
  min-width: 1580px;
  margin: 10px 0px;
  padding: 10px 10px;
  border-radius: 10px;
  background: var(--background);
}

.messageList-main-wrapper:hover .messageList-icons {
  display: block;
}

.messageList-icons {
  /* width: 30px; */
  height: 20px;
  padding-left: 15px;
  display: none;
}

.messageList-left > div > span {
  font-size: 0.85vw;
  font-weight: 500;
  color: var(--textBlack);
}

.messageList-left-column > span:nth-child(1) {
  font-size: 0.65vw;
  font-weight: 400;
  color: var(--font-600);
}

.messageList-left-column > img {
  width: 18px;
  height: 18px;
  /* padding-left: 5px; */
}

.messageList-left-column > span:nth-of-type(2) {
  font-size: 0.65vw;
  font-weight: 500;
  color: var(--font-600);
  padding-left: 5px;
}

.messageList-right > span {
  font-size: 0.75vw;
  font-weight: 400;
  color: var(--textBlack);
}

.asds {
  /* width: 50px;
  height: 25px; */
  padding: 1px 5px;
  border-radius: 42.45px;
  background: #99bc85;
  color: var(--background);
  font-size: 0.82vw;
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin-left: 10px;
}

.image-main-container {
  width: 62vh;
  position: relative;
}

.sendMsgWrapper {
  height: 70px;
}

.sendMsgWrapper-parent-wrapper .selectInputWrapper {
  width: 7vw;
  height: 30px !important;
  border-radius: 8px !important;
}
.sendMsgWrapper-parent-wrapper .selectOptionWrapper {
  border: 1px solid #000;
  z-index: 1500 !important;
}

.whiteBackgroundWhatsapp {
  min-height: 50px;
  background-color: var(--BG-0);
  height: auto;
}
.sendMSgFooter {
  height: 55px;
  padding: 0 20px;
}

.sendMsgWrapper .borderNumber {
  color: var(--font-400) !important;
  font-size: 0.85vw;
  font-weight: 400;
}

.sendMessage {
  border-radius: 8px;
  height: 30px;
  margin-left: 10px;
  border: 1px solid var(--card-border);
  font-size: 0.85vw;
  font-weight: 400;
  padding: 0 10px;
  &::placeholder {
    color: var(--font-400);
    font-size: 0.8vw;
    font-weight: 400;
  }
}
.sendMessage:focus {
  outline: none;
}

.duplicateButton {
  /* width: 100px; */
  height: 30px;
  background-color: var(--primary);
  color: white;
  font-size: 0.85vw;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  letter-spacing: 0.2px;
  padding: 1vw 1vw;
}
.customTimePicker {
  height: 300px;
  width: 90px;
  background-color: var(--BG-0);
}
.customTimePicker .hours {
  height: 100%;
  width: 50%;
  overflow-y: scroll;
  color: var(--textBlack);
  background-color: white;
  border-right: 1px solid var(--border-100);
}
.customTimePicker .minutes {
  height: 100%;
  width: 50%;
  overflow-y: scroll;
  color: var(--textBlack);
}
.minutes :hover {
  background-color: var(--BG-100);
  border-radius: 2px;
}
.hours :hover {
  background-color: var(--BG-100);
  border-radius: 2px;
}

.TimePicker {
  width: 100px;
  height: 32px;
  padding: 0px 0px;
  border-radius: 5px;
  border: 1px solid var(--lightGrey);
  background: var(--textWhite);
}

.image-main-container > img {
  width: 34vh;
  height: 65vh;
  background-color: var(--channelMessage);
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0px;
}
.image-main-container > div {
  width: 29.5vh;
  height: auto;
  max-height: 60vh;
  position: relative;
  top: 10px;
  left: 20px;
  right: 0;
  box-shadow: 0px 4px 30px 0px rgba(45, 48, 54, 0.1);
  background-color: white;
  border-radius: 16px;
  padding: 0px 0px;
}

.image-main-container > div::after {
  content: "";
  position: absolute;
  top: 0;
  left: -12px;
  width: 23.89px;
  height: 12px;
  transform: rotate(180deg);

  background: white;

  border-radius: 8px 80px 0px 0px;
}

.image-main-container > div > .text-container {
  width: 90%;
}
.text-container > span {
  font-size: 0.7vw;
  font-weight: 700;
  font-style: normal;
  line-break: anywhere;
}

/* .Button_name {
  font-size: 0.7vw;
  font-weight: 500;
  color: red;
} */
.text-container > p {
  font-size: 0.74vw;
  font-weight: 400;
  margin-top: 10px;
}
.text-container > div > span {
  font-size: 0.72vw;
  font-weight: 700;
  font-style: normal;
}
.image-main-container > div > span {
  width: 30vh;
  display: flex;
  justify-content: end;
  font-size: 0.62vw;
  color: var(--darkgrey);
  font-weight: 600;
  font-style: normal;
  padding: 10px 10px;
}
.image-main-container > div .final-container {
  display: flex;
  width: 100%;
  height: 42px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--BG-50);
}
.final-container > p {
  color: var(--dashboardprimary);
  font-size: 0.82vw;
  font-weight: 600;
  font-style: normal;
}

/* ----Whataspp campaigan ------ */

.whatsapp-container {
  width: 100%;
  height: 100%;

  padding: 0px 10px;
  border-left: 1px solid var(--border-50);
}
.containerWrapper {
  width: 100%;
  height: 91%;
  overflow-y: scroll;
}
.containerWrapper .main_container {
  width: 100%;
  height: auto;
  padding: 0px 10px;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);

  padding-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
}
.heading > img {
  width: 20px;
  height: 20px;
}
.heading > p {
  font-size: 0.79vw;
  font-weight: 700;
  padding: 0px 7px;
}
.heading > img:nth-of-type(2) {
  padding-left: 5px;
}
.message-container {
  width: auto;
  position: relative;
  height: 5vh;
  padding: 10px;
  border-radius: 7px;
  border: 1px solid var(--border-100) !important;
}
.message-container > div > img {
  width: 20px;
  height: 20px;
}
.message-container > div > p {
  font-size: 0.75vw;
  font-weight: 500;
  padding-left: 10px;
  color: var(--textBlack);
  /* color: var(--channelToggleSwitch); */
}
.popover-container {
  padding: 0px 7px;
  border-radius: 10px;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);
}
.popover-container > div > div {
  width: 40vh;
  /* height: 9vh; */
  padding: 10px 7px;
  border-bottom: none !important;
}
.popover-container > div > div > div > div > p {
  font-size: 0.8vw;
  font-weight: 700;
  padding: 0px 0px 5px 0px;
}
.popover-container > div > div > div > span {
  font-size: 0.76vw;
  font-weight: 600;
  color: var(--channelToggleSwitch);
}

.border-q {
  height: 8px !important;
  width: 8px !important;
  margin: auto 5px !important;
  border-radius: 2px !important;
}

.High .border-q {
  background-color: var(--primary) !important;
}

.Medium .border-q {
  background-color: var(--secondary) !important;
}

.Low .border-q {
  background-color: #f96056 !important;
}

.Unknown .border-q {
  background-color: var(--primary) !important;
}

.broadcast-status {
  font-size: 0.76vw;
  font-weight: 500;
}
.broadcast-duration {
  font-size: 0.76vw;
  font-weight: 500;
}
.menu-container {
  width: 90%;
  margin-left: 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #d1d3d8;
  margin-bottom: 10px;
}

.menu-container-main {
  padding: 0px 10px;
}
.menu_container_main_focused {
  color: var(--primary) !important;
  padding: 10px 10px;
  border-bottom: 1px solid var(--primary);
}
.menu-container-main > img {
  width: 16px;
  height: 16px;
}

.menu-container-main > span {
  font-size: 0.74vw;
  font-weight: 400;
  cursor: pointer;
  padding-left: 10px;
}

.menu-container > p {
  font-size: 0.98vw;
  font-weight: 500;
  cursor: pointer;
}

.upload-container {
  width: 100% !important;
  height: 15vh !important;
  border-radius: 5.35px;
  border: 1.5px dashed var(--font-100);

  background: var(--BG-25);

  input[type="file"] {
    display: none;
  }
}
.uploadLabel {
  width: 100% !important;
  height: 100% !important;
}
.uploadLabel > p {
  /* margin-top: 25px; */
  font-size: 0.61vw;
  font-weight: 600;
  color: var(--font-600);
}
.search-container {
  width: 100%;
  height: 3.5vh;
  border-radius: 7px;
  /* background: var(--channelButton); */
  padding: 10px 10px;
}
.search-container > img {
  width: 16px;
  height: 16px;
}
.search-container > input {
  background: var(--channelButton);
  color: var(--font-400);

  outline: none;
  border: none;
  font-size: 0.84vw;
  font-weight: 500;
}

.Existing-container {
  width: 100%;
  height: 7vh;
  padding: 20px 20px;
  border-bottom: 1px solid var(--font-50);
}

.Existing-container > div > p {
  font-size: 0.74vw;
  font-weight: 500;

  padding: 0px 7px;
}
.Existing-container > div > span {
  color: var(--interaction);
  font-size: 0.72vw;
  font-weight: 500;
}

.select-container {
  width: 100%;
  height: auto;
  padding: 15px 10px;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);
  border-radius: 10px;
  position: relative;
}
.select-container-wrapper {
  width: 37%;

  justify-content: space-evenly;
  cursor: pointer;
}
.select-container-wrapper > img {
  width: 16px;
  height: 16px;
}
.select-container-wrapper > span {
  font-size: 0.86vw;
  color: var(--channelToggleSwitch);
}
.select-calendar {
  width: auto;
  height: 32px;
  padding: 5px 5px;
  border-radius: 5px;
  border: 1px solid var(--lightGrey);
  background: var(--textWhite);
}
.select-calendar > p {
  font-size: 0.78vw;
  font-weight: 500;
  color: var(--font-400);
  padding-left: 5px;
}
.select-time {
  width: 100px;
  height: 32px;
  padding: 0px 0px;
  border-radius: 5px;
  border: 1px solid var(--lightGrey);
  background: var(--textWhite);
}
.select-time > span {
  font-size: 0.78vw;
  font-weight: 500;
  color: var(--font-400);
}
.search {
  width: 31vw;
  height: auto;
  /* min-height: 25vh; */

  /* overflow: scroll; */
  border-radius: 10px;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);
  box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);

  position: absolute;
  /* top: 70px; */
}

.search-template-container > div > img {
  width: 20px;
  height: 20px;
}

.search-template-info {
  width: auto;
  height: 4.5vh;
  padding-bottom: 10px;
  margin-top: 5px;
  border-bottom: 0.5px solid #f0f1f2;
  padding: 10px;
}

.search-template-info:hover {
  background: var(--channelButton);
  border-radius: 6px;
}

.search-template-info > p {
  font-size: 0.82vw;
  font-weight: 500;
  color: var(--interaction);
  padding-right: 7px;
  cursor: pointer;
}

.search-template-info > .status-color {
  font-size: 0.68vw;
  font-weight: 500;
  border-radius: 4px;
  align-items: center;
  padding: 3px 5px;
}
.status-container .status-color {
  font-size: 0.68vw;
  font-weight: 500;
  border-radius: 4px;
  align-items: center;
  padding: 0px 5px !important;
}

.select-container-main {
  position: absolute;
  /* top: 50%; */
}

.AddIcon > img {
  width: 35px;
  height: 30px;
}
.message-menu-container {
  width: 70%;
  padding-bottom: 10px;
}
.message-menu-container > .message {
  width: 35%;
  height: 4vh;
  border-radius: 6px;

  border: 1px solid var(--BG-50);
  background: var(--textWhite);
  box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
}
.message-menu-container > .message > img {
  width: 16px;
  height: 16px;
}
.message-menu-container > .message > span {
  font-size: 0.72vw;
  color: var(--channelToggleSwitch);
  font-weight: 500;
  color: var(--font-600);
}
.Reply > div {
  width: 50vh;
}
.Reply > div > p {
  font-size: 0.92vw;
  font-weight: 600;
  padding-right: 3px;
}
.TextEditor-main {
  width: 100%;
  padding-left: 40px;
}
.TextEditor {
  width: 60%;
  height: 3.5vh;
  border-radius: 6px;
  box-shadow: 0px 4px 20px 0px rgba(45, 48, 54, 0.1);
}
.status-container {
  width: 100%;
  height: auto;
  padding: 5px 10px;
  border-radius: 7px;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);
}
.status-container-wrapper {
  width: 100%;
}
.status-container-wrapper > div > span {
  font-size: 0.82vw;
  font-weight: 700;
}
.status-container > .status-container-wrapper > .status-calendar {
  width: 120px;
  height: 32px;
  padding: 5px 5px;
  border-radius: 5px;
  border: 1px solid var(--BG-100);
  background: var(--textWhite);
}
.status-container > .status-container-wrapper > .select-calendar > p {
  font-size: 0.76vw;
  font-weight: 500;
  color: var(--textBlack);
}
.status-container > .status-container-wrapper > .status-time {
  width: 100px;
  height: 32px;
  padding: 0px 0px;
  border-radius: 5px;
  border: 1px solid var(--lightGrey);
  background: var(--textWhite);
}
.status-container > .status-container-wrapper > .status-time > span {
  font-size: 0.78vw;
  font-weight: 500;
  color: var(--textBlack);
}
.whatsapp-status-color {
  padding-left: 20px;
}
.whatsapp-status-color > span {
  padding: 0 5px;
  font-size: 0.65vw;
  font-weight: 500;

  border-radius: 4px;
}
.whatsapp-status-color > img {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.status-container > .status-main-container > .status-line {
  width: 100%;
  height: 23vh;
  padding: 0px 0px;
  /* overflow-y: scroll; */
}
.status-line > .status-input > div {
  width: 100%;
  padding: 10px 10px;
  border-radius: 4px;
  border: 1px solid var(--BG-50);
  background: var(--textWhite);
}

.status-line {
  border-top: 1px solid var(--BG-50);
}

.status-input > div {
  display: flex;
  width: 21vh;
  height: 4vh;
  padding: 0px 0px;
  margin-top: 12px;
  border-radius: 4px;

  border: 1px solid var(--BG-50);
  background: var(--textWhite);
}
.FileMain {
  /* height: 6vh; */
  /* background: var(--primary-neutral, #f4f6f8); */
  margin-top: 15px;
  padding: 0px 16px;
  border-radius: 10px;
  border: 1px solid #f0f1f2;
  background: var(--Primary-White, #fff);
  box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
}

.FileMains {
  width: 40vw;
  height: 7vh;
  /* background: var(--primary-neutral, #f4f6f8); */
  margin-top: 15px;
  padding: 7px 10px;
  border-radius: 10px;
  border: 1px solid #f0f1f2;
  background: var(--Primary-White, #fff);
  box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
}
.File-container {
  width: 100%;
  padding: 7px 0px;
}
.File-container > .File-wrapper div > p > p {
  font-size: 0.65vw;
  font-weight: 500;
  /* padding: 7px; */
  color: var(--channelToggleSwitch);
}
.File-container > .File-wrapper div > p > span {
  color: var(--channelToggleSwitch);
  font-size: 0.65vw;
  font-weight: 500;
  padding-left: 10px;
}

.File-wrapper > div {
  width: 100% !important;
  padding: 5px 0px;
}
.File-wrapper > div > div {
  padding: 0px 10px;
}
.File-wrapper > div > div > img {
  width: 20px;
  height: 20px;
}

.File-container > .File-wrapper > div > div > p {
  font-size: 0.95vw;
  font-weight: 600;
  color: var(--textBlack);
  padding: 2px 5px;
}

.File-container > .File-wrapper div > p {
  font-size: 0.68vw;
  font-weight: 500;
  color: var(--font-400);
  padding: 1px 8px;
}

.file-upload-error {
  font-size: 075vw;
  font-weight: 600;
  color: #ff543e;
  background: #ffeeed;
  gap: 5px;
  padding: 2px 8px;
  border-radius: 6px;
}

.valid-data {
  color: "var(--textBlack)";
  font-weight: "700";
  font-size: "0.75vw";
}

.File-container > .File-wrapper div > span {
  font-size: 0.65vw;
  font-weight: 500;
  padding-right: 10px;
}

.file-main-container {
  padding: 0px 30px;
}
.File-content {
  height: 2vh;
  border-right: 1px solid var(--font-200);
}
.File-wrapper > div > img {
  width: 35px;
  height: 35px;
}

.search-memo {
  margin-bottom: 10px;
}
.sendMessage-button {
  padding: 15px 5px;
}
.select-icon {
  font-size: 0.88vw;
  font-weight: 600;
}
.Add_prospects_container {
  margin: 0px 0px 0px 10px;
}
.Add_prospects_container > p {
  font-weight: 600;
}
.Add_prospects_container > span {
  font-size: 0.78vw;
  font-weight: 500;
  color: var(--channelToggleSwitch);
  padding-left: 10px;
}
.DefineContent {
  margin: 15px 5px;
}
.DefineContent > img {
  width: 10px;
  height: 10px;
}

.DefineContent > p {
  padding-left: 10px;
  font-size: 0.833vw;
  font-weight: 400;
  color: var(--channelToggleSwitch);
}
