.container {
  min-width: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  height: 51vh;
  padding: 0 0 0 0.5vw !important;
  background: #f4f6f8;
  overflow: hidden;
}

.innerContainer {
  overflow: scroll;
  width: 100%;
  flex-grow: 1;
}

.container table {
  border-collapse: separate;
  border-spacing: 0 0.8vw !important;
}

.container table tr {
  overflow: hidden !important;
  border-top-left-radius: 0.5vw;
  border-bottom-left-radius: 0.5vw;
}

.container table thead tr {
  padding: 0 1vw;
}

.container thead tr > th {
  height: 100%;
  background: #f4f6f8;
  position: sticky;
  padding: 0.5vw 1vw !important;
  z-index: 2;
  top: 0;
  font-size: 0.8vw;
}

.container thead tr > :first-child {
  z-index: 3;
  left: 0;
  top: 0;
  text-align: left;
}

.container td {
  text-align: center;
  background: white;
  border: 1px solid #000;
  font-size: 0.75vw;
}

.container tbody tr > :first-child {
  position: sticky;
  z-index: 1;
  left: 0;
  border-top-left-radius: 0.5vw;
  border-bottom-left-radius: 0.5vw;
  overflow: hidden;
}

.container tbody tr > :last-child {
  border-top-right-radius: 0.5vw;
  border-bottom-right-radius: 0.5vw;
}

/* template preview */
/* .antd-modal {
  width: fit-content !important;
} */

.ant-skeleton .ant-skeleton-active .ant-skeleton-paragraph > li {
  width: 100% !important;
}
